<template>
  <div class="container-top">
    <router-view class="router-view"></router-view>
  </div>

</template>
<script>

  export default {
    name:"MyPages"
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .router-view
    background-color $gray3
    padding-bottom 120px
    min-height calc(100vh - 82px)

  @media(max-width:1024px)
    .router-view
      padding-top 0
      padding-bottom 0
</style>
